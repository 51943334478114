<template>
    <div>
        <card id="member-container">
            <div class="row col">
                <h6 class="m-0">User Activity <b>{{ user.full_name }}</b></h6>
            </div>
<!--            <div class="row">-->
<!--                <div class="col-sm-8 col-md-6 col-lg-4">-->
<!--                    <fg-input class="no-border"-->
<!--                              label="Search For Member by Last Name"-->
<!--                              type="text"-->
<!--                              v-model="searchCriteria" />-->
<!--                </div>-->
<!--                <div class="col-sm-4 col-md-6 col-lg-8">-->
<!--                    <n-button round type="secondary" @click.native="search()" class="mt-4">-->
<!--                        <i class="fas fa-search mr-2"></i> Search-->
<!--                    </n-button>-->
<!--                    <n-button round type="danger" @click.native="clearSearch()" class="ml-2 mt-4">-->
<!--                        <i class="fas fa-times mr-2"></i> Clear-->
<!--                    </n-button>-->
<!--                </div>-->
<!--            </div>-->

            <div class="row mt-3">
                <div class="col">
                    <small>
                       View all activity performed by the selected user. All activity related to controllers and bids is
                        logged in the system.
                    </small>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <table class="results table">
                        <thead>
                        <tr class="d-flex">
                            <th class="col-3 col-md-2">Timestamp</th>
                            <th class="col-3 col-md-2">Model</th>
                            <th class="col-6 col-md-8">Changes</th>
                        </tr>
                        </thead>

                        <tr v-for="activity in activities" :key="activity.id" class="d-flex">
                            <td class="col-3 col-md-2">
                                {{ activity.timestamp | localDisplay('MM/DD/YY HH:mm:ss', true) }}
                            </td>
                            <td class="col-3 col-md-2">
                                <n-button v-if="screenSize !== 'xs' &&   screenSize !== 'sm'"
                                          round
                                          block
                                          class="bid-button small d-inline-block"
                                          type="success"
                                          @click.native="showModel(activity)">
                                    <i v-if="activity.action === 'updated'" class="fas fa-edit mr-1" />
                                    <i v-else-if="activity.action === 'created'" class="fas fa-plus mr-1" />
                                    <i v-else-if="activity.action === 'deleted'" class="fas fa-trash mr-1" />
                                    {{ activity.model_name }}
                                </n-button>
                                <span v-else>
                                    <i v-if="activity.action === 'updated'" class="fas fa-edit text-warning mr-1" />
                                    <i v-else-if="activity.action === 'created'" class="fas fa-plus text-success mr-1" />
                                    <i v-else-if="activity.action === 'deleted'" class="fas fa-trash text-danger mr-1" />
                                    {{ activity.model_name }}
                                </span>
                            </td>
                            <td class="col-6 col-md-8">
                                <div v-for="(v, k) in filterChanges(activity.changes)" :key="k" class="row">
                                    <div class="col-6 col-md-2 text-right pr-1 font-weight-bold">{{ k | uppercase }}</div>
                                    <div class="col-6 col-md-10 border-0">{{ v }} <i class="strikethrough text-danger">{{ activity.changes.old ? activity.changes.old[k] : '' }}</i></div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <el-pagination background
                           class="d-block mx-auto mt-3 text-center"
                           @current-change="page"
                           layout="prev, pager, next, jumper, ->, total, slot"
                           :current-page.sync="currentPage"
                           :page-size="50"
                           :total="pagination.total" />
        </card>
        <el-dialog title="Activity Details" width="60%" :visible.sync="visible" append-to-body @close="closeDetails()">
          <div class="row justify-content-center mt-2">
              <div class="col-10">
                  <h5 class="text-center mb-0">{{ viewModel.model_name + ' - ' + viewModel.model_id | uppercase }}</h5>
                  <table class="table">
                      <tr v-for="(v, k) in viewModel.model" :key="k">
                          <td class="text-right pr-3 font-weight-bold">{{ k | uppercase }}</td>
                          <td>{{ v }}</td>
                      </tr>
                  </table>
              </div>
          </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Dialog, Pagination } from 'element-ui'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const AuthRepository = RepositoryFactory.get('auth')

export default {
  name: 'user-history',
  components: {
    [Dialog.name]: Dialog,
    [Pagination.name]: Pagination
  },
  data () {
    return {
      activities: [],
      pagination: {},
      currentPage: 1,
      viewModel: {},
      visible: false,
      searchCriteria: null,
      user: {}
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility'
    })
  },
  methods: {
    /** Retrieve messages from server */
    fetchActivity () {
      AuthRepository.getActivity(this.$route.params.user_id, this.currentPage)
        .then(r => {
          this.activities = _.pick(r.data, 'data').data
          this.pagination = _.omit(r.data, 'data')
        })
    },
    fetchUser () {
      AuthRepository.getUser(this.$route.params.user_id, true)
        .then(r => {
          this.user = r.data
        })
    },
    /** View requested message */
    showModel (activity) {
      // MessageRepository.getMessage(message_id)
      // .then(r => {
      this.viewModel = activity
      this.visible = true
      // })
    },
    closeDetails () {
      this.viewModel = {}
      this.visible = false
    },
    page (page) {
      this.currentPage = page
      this.fetchActivity()
    },
    filterChanges (changes) {
      const ignore = ['created_at', 'updated_at', 'deleted_at']
      return _.pickBy(changes.attributes, (v, k) => !ignore.includes(k))
    }
    // search() {
    //     MessageRepository.search(this.searchCriteria)
    //     .then(r => {
    //         this.messages = _.pick(r.data, 'data').data;
    //         this.pagination = _.omit(r.data, 'data');
    //     })
    // },
    // clearSearch() {
    //     this.currentPage = 1;
    //     this.searchCriteria = null;
    //     this.fetchActivity();
    // }
  },
  created () {
    this.fetchActivity()
    this.fetchUser()
  },
  mounted () {},
  watch: {}
}
</script>
<style></style>
